import React from 'react';
import { NavLink } from "react-router-dom"

class Legal extends React.Component {
    render() {
        return (
            <div className={"legal"}>
                <div className={"legal_termAndConditions"}>
                    <h1>Terms & Conditions</h1>
                    <NavLink to={'/luukit-termsAndConditions'}>
                        <h2>LuukIt</h2>
                    </NavLink>
                    <NavLink to={'/dona-termsAndConditions'}>
                        <h2>Dona</h2>
                    </NavLink>
                    <NavLink to={'/nafasi-organization-terms'}>
                        <h2>Nafasi Org.</h2>
                    </NavLink>
                    <NavLink to={'/nafasi-user-terms'}>
                        <h2>Nafasi User.</h2>
                    </NavLink>
                    <NavLink to={'/kingo-termsAndConditions'}>
                        <h2>Kingo</h2>
                    </NavLink>
                    <NavLink to={'/wese-termsAndConditions'}>
                        <h2>Wese</h2>
                    </NavLink>
                </div>

                <div className={"legal_privacyPolicy"}>
                    <h1>Privacy Policy</h1>
                    <NavLink to={"/luukit-privacy-policy"}>
                        <h2>LuukIt</h2>
                    </NavLink>

                    <NavLink to={"/taxmy-privacy-policy"}>
                        <h2>Taxmy</h2>
                    </NavLink>

                    <NavLink to={"/bavla-privacy-policy"}>
                        <h2>Bavla</h2>
                    </NavLink>

                    <NavLink to={"/liveball-privacy-policy"}>
                        <h2>LiveBall</h2>
                    </NavLink>

                    <NavLink to={"/lalert-privacy-policy"}>
                        <h2>Lalert</h2>
                    </NavLink>

                    <NavLink to={"/dona-privacy-policy"}>
                        <h2>Dona</h2>
                    </NavLink>

                    <NavLink to={"/bigstep-privacy-policy"}>
                        <h2>BigStep</h2>
                    </NavLink>

                    <NavLink to={"/thegrand-privacy-policy"}>
                        <h2>The Grand</h2>
                    </NavLink>

                    <NavLink to={"/cbta-privacy-policy"}>
                        <h2>CBTA</h2>
                    </NavLink>

                    <NavLink to={"/nafasi-privacy-policy"}>
                        <h2>Nafasi</h2>
                    </NavLink>
                    <NavLink to={"/afcl-privacy-policy"}>
                        <h2>Afcl</h2>
                    </NavLink>
                    <NavLink to={"/kingo-privacy-policy"}>
                        <h2>Kingo</h2>
                    </NavLink>
                    <NavLink to={"/wese-privacy-policy"}>
                        <h2>Wese</h2>
                    </NavLink>
                    <NavLink to={"/hymns-privacy-policy"}>
                        <h2>Hymns</h2>
                    </NavLink>
                </div>

                <div className={"legal_userGuidelines"}>
                    <h1>User Guidelines</h1>
                    <NavLink to={'/user-guidelines'}>

                    </NavLink>
                    <h2>Pomelor</h2>
                </div>
            </div>
        );
    }
}

export default Legal;