import React from 'react';

class HymnsPrivacyPolicy extends React.Component {

    render() {

        return (
            <div className={"luukitPrivacyPolicy"}>
                <embed
                    src="https://firebasestorage.googleapis.com/v0/b/sdahymnsio.firebasestorage.app/o/legal%2FPrivacy%20Policy%20for%20Hymns%20App.pdf?alt=media&token=ccd3624a-cb5c-41f4-b911-1e2593c5a4e1"
                    type="application/pdf"
                    width="100%"
                    height="100%"
                />
            </div>
        );
    }
}

export default HymnsPrivacyPolicy;